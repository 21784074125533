import { NormalButton, DontCareButton } from "../Components/Buttons"
import { budgetPick } from "../Services/API"
import React, { Component } from 'react';

class Budget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }
    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }

    render () {
        return (
            <div className={this.state.isVisible ? "isVisible": "isNotVisible"}>
                <h2>With a budget...?</h2>
                <NormalButton funcPick={budgetPick} text={["Unlimited pockets", <span key="7" className="btn-lowertext">(Over $100 million)</span>]} data="100000000 AND 100000000000" link="/collection"/>
                <NormalButton funcPick={budgetPick} text={["Pretty baller", <span key="8" className="btn-lowertext">(Around $50 million)</span>]} data="25000000 AND 100000000" link="/collection"/>
                <NormalButton funcPick={budgetPick} text={["Respectable", <span key="9" className="btn-lowertext">(Around $10 million)</span>]} data="5000000 AND 25000000" link="/collection"/>
                <NormalButton funcPick={budgetPick} text={["A tiny amount", <span key="10" className="btn-lowertext">(A few million)</span>]} data="500000 AND 5000000" link="/length"/>
                <NormalButton funcPick={budgetPick} text={["Someone's life savings", <span key="11" className="btn-lowertext">(Under $500,000)</span>]} data="0 AND 500000" link="/length"/>
                <DontCareButton funcPick={budgetPick} text="Mo' money mo' problems" data="0 AND 100000000000" link="/collection"/>
            </div>
        );
    };
};

export default Budget;