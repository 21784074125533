import { NormalButton, DontCareButton } from "../Components/Buttons"
import { collectionPick } from "../Services/API"
import React, { Component } from 'react';

class Collection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }
    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }

    
    render () {
        return (
            <div className={this.state.isVisible ? "isVisible": "isNotVisible"}>
            <h2>Part of a series?</h2>
                <NormalButton funcPick={collectionPick} text="Yes" data='IS NOT NULL)' link="/votecount"/>
                <NormalButton funcPick={collectionPick} text="No" data='IS NULL)' link="/votecount"/>
                <DontCareButton funcPick={collectionPick} text="I don't care" data="IS NULL OR 'belongs_to_collection' IS NOT NULL)" link="/votecount"/>
            </div>
        );
    };
};

export default Collection;