import { StartButton } from "../Components/Buttons"
import React, { Component } from 'react';

// import { CSSTransition } from "react-transition-group";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
        this.fadeOut = this.fadeOut.bind(this)
    }

    fadeOut() {
        setTimeout( () => {
            this.setState({isVisible : false});
        }, 2000)

        if (!this.state.isVisible) {
            return Promise.resolve("ok im done")

        }
    }
    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }
    render () {
        return (
            
            <div className="Home">
                <div className={this.state.isVisible ? "isVisibleHomeTitle": "isNotVisibleHomeTitle"}>
                    <h4 className="Home-Title">Looking for the perfect film?</h4>
                </div>

                <div className={this.state.isVisible ? "isVisibleHomePara": "isNotVisibleHomePara"}>
                    <p className="Home-Para">Take a quick journey to be matched with a movie based on your preferences.</p>
                </div>
                <div className={this.state.isVisible ? "isVisibleHomeButton": "isNotVisibleHomeButton"}>
                    <StartButton text="Start" link="/stream"/>
                </div>
            </div>

            // <div>
            // <NormalButtonNoFunc text="Start" link="/genre" animation={this.fadeOut}/>
            // <button onClick={() => this.setState({isVisible: false})}>Start</button>
            // <CSSTransition
            //     unmountOnExit
            //     in={this.state.isVisible}
            //     timeout={2000}
            //     classNames="my-node"
            // >            
            // <div style={{ height: "100px", width: "100px", background: "red" }}>
            // Your Card
            // </div>

            // </CSSTransition>
            // </div>
        );
    };
};

export default Home;