export function RandomGenre(props) {
    switch(props.option) {
        //1 = Fast Paced
        //had to include sql query here as cant use IN with JSON sql
        case 1:
            return `genres->'$[0].name' = 'Action'
                    OR
                    genres->'$[0].name' = 'Drama'
                    OR
                    genres->'$[0].name' = 'Adventure'
                    OR
                    genres->'$[0].name' = 'War'
                    OR
                    genres->'$[0].name' = 'Crime'
                    `;
        case 2:
            return `genres->'$[0].name' = 'Comedy'
                    OR
                    genres->'$[0].name' = 'Romance'
                    OR
                    genres->'$[0].name' = 'Family'            
                    `        
        case 3:
            return `genres->'$[0].name' = 'History'
                    OR
                    genres->'$[0].name' = 'Mystery'          
                    `        
        case 4:
            return `genres->'$[0].name' = 'Thriller'
                    OR
                    genres->'$[0].name' = 'Horror' 
                    `
        case 5:
            return `genres->'$[0].name' = 'Fantasy'
                    OR
                    genres->'$[0].name' = 'Science Fiction' 
                    `        
        case 6:
            return `genres->'$[0].name' = 'Action'
                    OR
                    genres->'$[0].name' = 'Crime'
                    OR
                    genres->'$[0].name' = 'Adventure'
                    OR                    
                    genres->'$[0].name' = 'Dramatic'            
                    OR
                    genres->'$[0].name' = 'War'
                    OR
                    genres->'$[0].name' = 'Science Fiction'            
                    OR
                    genres->'$[0].name' = 'Comedy'
                    OR
                    genres->'$[0].name' = 'Romance'            
                    OR
                    genres->'$[0].name' = 'Family'
                    OR
                    genres->'$[0].name' = 'Fantasy'            
                    OR
                    genres->'$[0].name' = 'History'
                    OR
                    genres->'$[0].name' = 'Documentary'            
                    OR
                    genres->'$[0].name' = 'Animation'
                    OR
                    genres->'$[0].name' = 'Thriller'
                    OR
                    genres->'$[0].name' = 'Horror'
                    OR
                    genres->'$[0].name' = 'Western'            
                    OR
                    genres->'$[0].name' = 'Music'            
                    `        
        default:
    }
}

// function FastPaced() {
//     var random = Math.floor(Math.random() * Math.floor(5)) + 1

//     switch(random) {
//         case 1:
//             return 'Action';
//         case 2:
//             return 'Drama';
//         case 3:
//             return 'War';
//         case 4:
//             return 'Crime';
//         case 5:
//             return 'Thriller';
//         default:
//     }
// }

// function Gentle() {
//     var random = Math.floor(Math.random() * Math.floor(3)) + 1

//     switch(random) {
//         case 1:
//             return 'Comedy';
//         case 2:
//             return 'Romance';
//         case 3:
//             return 'Family';
//         default:
//     }
// }


// function Mysterious() {
//     var random = Math.floor(Math.random() * Math.floor(4)) + 1

//     switch(random) {
//         case 1:
//             return 'Fantasy';
//         case 2:
//             return 'History';
//         case 3:
//             return 'Mystery';
//         case 4:
//             return 'Documentary';
//         default:
//     }
// }

// function FullyRandomGenre() {
//     var random = Math.floor(Math.random() * Math.floor(16)) + 1

//     switch(random) {
//         case 1:
//             return 'Action';
//         case 2:
//             return 'Crime';
//         case 3:
//             return 'Dramatic';
//         case 4:
//             return 'War';
//         case 5:
//             return 'Sci-Fi';
//         case 6:
//             return 'Thriller';
//         case 7:
//             return 'Comedy';
//         case 8:
//             return 'Romantic';
//         case 9:
//             return 'Family';
//         case 10:
//             return 'Fantasy';
//         case 11:
//             return 'History';
//         case 12:
//             return 'Documentary';
//         case 13:
//             return 'Animation';
//         case 14:
//             return 'Western';
//         case 15:
//             return 'Music';
//         default:
//     }

// }
export default RandomGenre