import Axios from 'axios';

//post to nodejs
export function streamPick(stream){
  Axios.post("/streamPick", {
    data: stream,
  })
}

export function genrePick(genre){
    Axios.post("/genrePick", {
      data: genre,
    })
}

export function timePick(time){
  Axios.post("/timePick", {
    data: time,
  })
}

export function ratingPick(rating){
  Axios.post("/ratingPick", {
    data: rating,
  })
}

export function votecountPick(votecount){
  Axios.post("/votecountPick", {
    data: votecount,
  })
}

export function lengthPick(length){
  Axios.post("/lengthPick", {
    data: length,
  })
}

export function collectionPick(collection){
  Axios.post("/collectionPick", {
    data: collection,
  })
}

export function budgetPick(collection){
  Axios.post("/budgetPick", {
    data: collection,
  })
}


export function resetVar(collection){
  Axios.post("/resetVar", {
    data: collection,
  })
}
// export function movieGet(){
//   Axios.get("/movieGet")
// }

export default genrePick;