import { NormalButton, DontCareButton } from "../Components/Buttons"
import { lengthPick } from "../Services/API"
import React, { Component } from 'react';


class Length extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }
    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }


    render () {
        return (
            <div className={this.state.isVisible ? "isVisible": "isNotVisible"}>
                <h2>For how long...</h2>
                <NormalButton funcPick={lengthPick} text={["A shorter film", <span key="14" className="btn-lowertext">(~90 minutes)</span>]} data="'0' AND '100'" link="/pick"/>
                <NormalButton funcPick={lengthPick} text={["Average length", <span key="15" className="btn-lowertext">(1.5 to 2.5 hours)</span>]} data="'100' AND '150'" link="/pick"/>
                <NormalButton funcPick={lengthPick} text={["A long film", <span key="16" className="btn-lowertext">(2.5 hours+)</span>]} data="'150' AND '10000'" link="/pick"/>
                <DontCareButton funcPick={lengthPick} text="Time is relative" data="'0' AND '10000'" link="/pick"/>
            </div>
        );
    };
};

export default Length;