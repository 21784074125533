import React, { Component } from 'react';

class Footer extends Component {
    render () {
        return (
            <footer className="footer">     
                moviedecider.com · The Fun Movie Recommendation Engine · 2024
            </footer>
        );
    };
};

export default Footer;
