import * as React from 'react'
import Navbar from '@acto/react-navbar'
import logo from "../Images/logo-milliard-sm.png";
import { Link } from 'react-router-dom';

const navTheme = {
    mainColor: '#ffffff',
    menuBgColor: '#1b1b1b',
    backgroundColor: '#1b1b1b',
    sliderWidth: '40%'
  }
  
  const navBrand = <a href="/"><img className="Navbar-logo" src={logo} alt="logo" width="132" height="39"/></a>
  
  const rightLinks = (
    <>
      <a className="Navbar-Links" href="/">Home</a>
      {/* <Link to="/genre">Play</Link> */}
      <Link className="Navbar-Links" to="/about">About</Link>
      <Link className="Navbar-Links" to="/contactus">Contact</Link>
      <a href="https://tvdecider.com">TV Decider</a>

    </>
  )

export function NavbarMD() {
  return (
    <div className="app">
      <Navbar
        className="navbar" // style .navbar in your css
        menuClassName="navbar--menu" // style .navbar--menu in your css
        brand={navBrand}
        theme={navTheme}
        rightLinks={rightLinks}
        
        // shouldHideOnScroll
      />

    </div>
  )
}

export default NavbarMD