// import { MovieDecide } from "../Components/Pages"
// import api from "../Services/API"
import React, { Component } from 'react';
import axios from 'axios';
import { RestartButton } from "../Components/Buttons"
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FaSearch, FaDice } from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner'
import { timePick, budgetPick } from "../Services/API"
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/opacity.css';

class Pick extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: '',
            dataIdx: 0, //set to 1 as 0 is the initial element and 1 is the reroll
            movie: '',
            date: '',
            poster_path: '',
            imdb_link: '',
            overview: '',
            genres: [],
            vote_average: '',
            imdb_rating: '',
            tmdb_id: '',
            streaming_provider: '',
            isLoading: true,
            isStream: false,
            isPrime: false,
            isNetflix: false,
            isStan: false,
            isDisney: false,
            isPara: false
            
        }
    }

    fetchDataFake() {
        //Load images during load state into cache
        const newImage = new Image();
        const imdbImage = new Image();
        const tmdbImage = new Image();
        const netflixImage = new Image();

        newImage.src = this.state.poster_path
        imdbImage.src = "imdb-sm.png" 
        tmdbImage.src = "tmdb-sm.png"
        netflixImage.src = "netflix-sm.png"

        // newImage.onload = () => {
        //     this.setState({isLoading : false});
        // }
        
        //simulate 1s load to give app some nice feel
        setTimeout( () => {
            this.setState({isLoading : false});
        }, 1000)
    }

    fetchDataFakeReroll() {
        //Load image during load state into cache
        const newImage = new Image();
        newImage.src = this.state.poster_path
        // newImage.onload = () => {
        //     this.setState({isLoading : false});
        // }

        //simulate 0.5s load to give app some nice feel
        setTimeout( () => {
            this.setState({isLoading : false});
        }, 500)
    }
    componentDidMount() {
        this.getAllMovies();
    }

    getAllMovies() {
        axios.get('/movieGet')
            .then(response => response.data)
            .then(data => {
                // console.log(data)
                //if the data exists then set the movie that has been received from backend
                if (data[0]) {
                    // console.log(data)
                    // console.log("Movies found: "+data.length)
                    //Genres must be put through JSON.parse in order to be mapped in render()
                    var genresParsed = JSON.parse(data[0].genres)
                    this.setState({
                        data: data,
                        
                        //Set states to initial first film in data array 
                        movie: data[0].title,
                        date: '(' + data[0].release_date.split("-")[0] + ')',
                        poster_path: 'resources/poster_images' + data[0].poster_path,
                        imdb_link: 'https://www.imdb.com/title/' + data[0].imdb_id,
                        overview: data[0].overview,
                        genres: genresParsed,
                        vote_average: data[0].vote_average,
                        imdb_rating: data[0].imdb_rating,
                        tmdb_id: 'https://www.themoviedb.org/movie/' + data[0].id,
                        streaming_provider: data[0].streaming_provider
                    });
                    this.getStreamProviders()
                    this.fetchDataFake();
                    // console.log(data)

                }
                else {
                    this.setState({ 
                        movie: "No movie found!",
                        overview: "A movie could not be found based on these parameters. Please expand your search or try again."
                    });
                    this.fetchDataFake();
                }

            });

    }

    async expandSearch() {
        //To expand our search, we can simply post to the /timePick route and expand to all-time movies
        timePick("'1900-01-01' AND '2099-01-01'")

        //Also expand budget if needed
        budgetPick("0 AND 100000000000")

        //After this has posted to timePick API then get movies with new variables
        this.getAllMovies()

        this.setState({isLoading: true})
        //Loading spinner
        this.fetchDataFake();

    }

    async changeIdx() {
        if (this.state.dataIdx < this.state.data.length - 1) {
            this.setState({
                dataIdx: this.state.dataIdx + 1
            })
        }
        else {
            this.setState({
                dataIdx: 0
            })            
        }        
    }
    async getStreamProviders() {

        //set them all to false in beginning
        this.setState({
            isStream: false,
            isPrime: false,
            isNetflix: false,
            isDisney: false,
            isStan: false,
            isPara: false
        })
        //if it actually exists else if null will crash
        if (this.state.streaming_provider) {
            
            this.setState({ isStream: true })

            if (this.state.streaming_provider.includes("Amazon Prime Video")) {
                this.setState({ isPrime: true })
            }
            if (this.state.streaming_provider.includes("Netflix")) {
                this.setState({ isNetflix: true })
            }
            if (this.state.streaming_provider.includes("Disney")) {
                this.setState({ isDisney: true })
            }
            if (this.state.streaming_provider.includes("Stan")) {
                this.setState({ isStan: true })
            }
            if (this.state.streaming_provider.includes("Paramount")) {
                this.setState({ isPara: true })
            }
        }
    }
    async nextMovie() {
        //increment index if less than length and traverse through data array of films when re-roll
        //go through each by one as list is already randomized in sql query

        //Please await the state change before messing up everything :)
        await this.changeIdx()

        
        if (this.state.data[this.state.dataIdx]) {
            //Genres must be put through JSON.parse in order to be mapped in render()
            var genresParsed = JSON.parse(this.state.data[this.state.dataIdx].genres)
            this.setState({
                isLoading: true, 
                movie: this.state.data[this.state.dataIdx].title,
                date: '(' + this.state.data[this.state.dataIdx].release_date.split("-")[0] + ')',
                poster_path: 'resources/poster_images' + this.state.data[this.state.dataIdx].poster_path,
                imdb_link: 'https://www.imdb.com/title/' + this.state.data[this.state.dataIdx].imdb_id,
                overview: this.state.data[this.state.dataIdx].overview,
                genres: genresParsed,
                vote_average: this.state.data[this.state.dataIdx].vote_average,
                imdb_rating: this.state.data[this.state.dataIdx].imdb_rating,
                tmdb_id: 'https://www.themoviedb.org/movie/' + this.state.data[this.state.dataIdx].id,
                streaming_provider: this.state.data[this.state.dataIdx].streaming_provider
            });
            //please await until we get streaming providers :)
            await this.getStreamProviders()

        //after settings states, pretend to fetch data and then end loading state
        this.fetchDataFakeReroll();
        }
    }
    render () {
        //loading state
        const { isLoading, isStream, isPrime, isNetflix, isDisney, isStan, isPara } = this.state;

        //grab genres from state and perform map to get all genre names
        const { genres  } = this.state;
        const specificGenres = genres.map(genre => " "+genre.name).toString()

        // console.log(this.state.streaming_provider)

        // console.log(isPrime,isNetflix,isDisney,isStan,isPara)

        //create google search link
        const googleSearch = "https://www.google.com/search?q=" + this.state.movie + " " + this.state.date


        if (this.state.data.length > 1) {
            
            return (
                <div>
                    { isLoading && 
                    <div className="Pick-border-spinner">
                        <div className="spinner">
                        <Spinner animation="border" className="spinner"></Spinner>
                        </div>
                    </div>
                    }
                    
                    { !isLoading && 

                    <div className="Pick-border">
                        <div className="Pick">

                            <div className="Pick-left-half">
                                <img width="125px" height="188px" src={this.state.poster_path} className="Pick-image" alt="" />
                                {/* <a href={this.state.tmdb_id} target="_blank" rel="noopener noreferrer">
                                    
                                    <Button
                                        className="BtnChoicePick"
                                        variant="primary" 
                                        size="sm"
                                        block 
                                        ><i><FaShareSquare className="icon-search"/>&#8287;</i>Share
                                    </Button>                                    
                                </a> */}
                            </div>

                            <div className="Pick-right-half">
                                <h3 className="Pick-movietitle">
                                    {this.state.movie} {this.state.date}
                                </h3>
                                <p className="Pick-genres">
                                    {specificGenres}
                                </p>
                                <p className="Pick-overview">
                                    {this.state.overview}
                                </p>
                                <p className="Pick-ratings">
                                    <a href={this.state.imdb_link} target="_blank" rel="noopener noreferrer"><img width="42px" height="20px" src="imdb-sm.png" alt="imdb"></img></a>
                                    &#8287;
                                    <span className="Pick-ratings-text">
                                        {" "+this.state.imdb_rating+"/10 "}
                                    </span>

                                    &#8287;&#8287;

                                    <a href={this.state.tmdb_id} target="_blank" rel="noopener noreferrer"><img width="42px" height="18px" src="tmdb-sm.png" alt="tmdb"></img></a>
                                    &#8287;
                                    <span className="Pick-ratings-text">
                                        {" "+Number(this.state.vote_average).toFixed(1)+"/10"}
                                    </span>
                                    <a href={googleSearch} target="_blank" rel="noopener noreferrer" className="BtnChoicePick">
                                        <Button
                                            className="BtnChoicePick"
                                            variant="success" 
                                            size="sm" 
                                            block
                                            ><i><FaSearch className="icon-search"/>&#8287;</i>Search on Google
                                        </Button>
                                    </a>        
                                </p>
                                <p className="Pick-streaming">
                                    {isStream &&
                                        <text>Watch on:&#8287;</text>
                                    }
                                    {isNetflix && 
                                        <a href="https://netflix.com" target="_blank" rel="noopener noreferrer"><img src="netflix-sm.png" alt="stream"></img></a>
                                    }
                                    {isStan && 
                                        <a href="https://stan.com.au" target="_blank" rel="noopener noreferrer"><img src="stan-sm.png" alt="stream"></img></a>
                                    }                                    
                                    {isPrime && 
                                        <a href="https://primevideo.com" target="_blank" rel="noopener noreferrer"><img src="prime-sm.png" alt="stream"></img></a>
                                    }
                                    {isDisney && 
                                        <a href="https://disneyplus.com" target="_blank" rel="noopener noreferrer"><img src="disney-sm.png" alt="stream"></img></a>
                                    }
                                    {isPara && 
                                        <a href="https://paramountplus.com" target="_blank" rel="noopener noreferrer"><img src="paramount-sm.png" alt="stream"></img></a>
                                    }
                                </p>
                            </div>                   
                        </div>
                        <div className="Pick-buttons">
                            <div className="BtnChoicePick">
                                <Button
                                    variant="outline-danger" 
                                    size="customSize" 
                                    block
                                    onClick={async () => {await this.nextMovie()}}
                                    ><i><FaDice className="icon-reroll"/> </i>Re-roll ({this.state.dataIdx+1}/{this.state.data.length})
                                </Button>
                            </div>

                            <Link to="/stream" className="BtnChoicePick">
                            <RestartButton />
                            </Link>
                        </div>
                    </div>}

                </div>
            );            
        }
        else if (this.state.data.length === 1) {
            return (
                <div>
                    { isLoading && 
                    <div className="Pick-border-spinner">
                        <div className="spinner">
                            <Spinner animation="border" className="spinner"></Spinner>
                        </div>
                    </div>
                    }
                    
                    { !isLoading && 
                    <div className="Pick-border">
                        <div className="Pick">

                            <div className="Pick-left-half">
                                <img width="125px" height="188px" src={this.state.poster_path} className="Pick-image" alt="" />
                            </div>

                            <div className="Pick-right-half">
                                <h3 className="Pick-movietitle">
                                    {this.state.movie} {this.state.date}
                                </h3>
                                <p className="Pick-genres">
                                    {specificGenres}
                                </p>
                                <p className="Pick-overview">
                                    {this.state.overview}
                                </p>
                                <p className="Pick-ratings">
                                    <a href={this.state.imdb_link} target="_blank" rel="noopener noreferrer"><img width="42px" height="20px" src="imdb.png" alt="imdb"></img></a>
                                    &#8287;
                                    <span className="Pick-ratings-text">
                                        {" "+this.state.imdb_rating+"/10 "}
                                    </span>

                                    &#8287;&#8287;

                                    <a href={this.state.tmdb_id} target="_blank" rel="noopener noreferrer"><img width="42px" height="20px" src="tmdb.svg" alt="tmdb"></img></a>
                                    &#8287;
                                    <span className="Pick-ratings-text">
                                        {" "+this.state.vote_average+"/10"}
                                    </span>

                                    <a href={googleSearch} target="_blank" rel="noopener noreferrer" className="BtnChoicePick">
                                        <Button
                                            className="BtnChoicePick"
                                            variant="success" 
                                            size="sm" 
                                            block
                                            ><i ><FaSearch className="icon-search"/>&#8287;</i>Search on Google
                                        </Button>
                                    </a>
                                </p>
                                <p className="Pick-streaming">
                                    {isStream &&
                                        <text>Watch on:&#8287;</text>
                                    }
                                    {isNetflix && 
                                        <a href="https://netflix.com" target="_blank" rel="noopener noreferrer"><img src="netflix-sm.png" alt="stream"></img></a>
                                    }
                                    {isStan && 
                                        <a href="https://stan.com.au" target="_blank" rel="noopener noreferrer"><img src="stan-sm.png" alt="stream"></img></a>
                                    }                                    
                                    {isPrime && 
                                        <a href="https://primevideo.com" target="_blank" rel="noopener noreferrer"><img src="prime-sm.png" alt="stream"></img></a>
                                    }
                                    {isDisney && 
                                        <a href="https://disneyplus.com" target="_blank" rel="noopener noreferrer"><img src="disney-sm.png" alt="stream"></img></a>
                                    }
                                    {isPara && 
                                        <a href="https://paramountplus.com" target="_blank" rel="noopener noreferrer"><img src="paramount-sm.png" alt="stream"></img></a>
                                    }
                                </p>
                            </div>
                        
                        
                        
                        
                        
                        </div> 
                        <div className="Pick-buttons">
                            <Link to="/stream" className="BtnChoicePick">
                                <RestartButton />
                            </Link>
                        </div>
                    </div>}

                </div>
            );            
        }
        else {
            //have the !isloading down below to avoid any issues
            return (
                <div>
                    { isLoading &&
                    <div className="Pick-border-spinner">                     
                        <div className="spinner">
                            <Spinner animation="border" className="spinner"></Spinner>
                        </div>
                    </div>
                    }
                    { !isLoading && 
                    <div className="Pick-border">
                        <div className="Pick-nomovie">
                            {/* <h3 className="Pick-movietitle">
                                {this.state.movie}
                            </h3> */}
                                {/* {this.state.overview} */}
                                <h3 className="Pick-nomovie-text">No movie found!</h3>
                                Please expand your search time period or try again.
                        <div className="Pick-buttons">
                            <div className="BtnChoicePick">
                                <Button
                                    variant="primary" 
                                    size="customSize" 
                                    block
                                    onClick={async () => {await this.expandSearch()}}
                                    ><i><FaSearch className="icon-reroll"/> </i>Expand search
                                </Button>
                            </div>
                            <Link to="/stream" className="BtnChoicePick">
                                <RestartButton />
                            </Link>
                        </div>
                        </div>

                    </div>
                    }
                </div>
            );              
        }

    };
};

export default Pick;
