import { NormalButtonSmall, DontCareButton } from "../Components/Buttons"
import { genrePick } from "../Services/API"
import { RandomGenre } from "../Services/RandomGenre"
import React, { Component } from 'react';

class GenreAll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }

    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }

    componentDidMount() {
        this.fadeIn();
    }

    render () {
        return (
            <div className={this.state.isVisible ? "isVisible": "isNotVisible"}>
            <h2>Specifically...</h2>
                <div className="Specific">
                    <div className="Specific-left">
                        <NormalButtonSmall funcPick={genrePick} text="Action" data="genres->'$[0].name' = 'Action'"  link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Crime" data="genres->'$[0].name' = 'Crime'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Adventure" data="genres->'$[0].name' = 'Adventure'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Dramatic" data="genres->'$[0].name' = 'Drama'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="War" data="genres->'$[0].name' = 'War'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Sci-Fi" data="genres->'$[0].name' = 'Science Fiction'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Thriller" data="genres->'$[0].name' = 'Thriller'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Horror" data="genres->'$[0].name' = 'Horror'" link="/time"/>
                        
                    </div>
                    <div className="Specific-right">
                        <NormalButtonSmall funcPick={genrePick} text="Comedy" data="genres->'$[0].name' = 'Comedy'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Romance" data="genres->'$[0].name' = 'Romance'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Family" data="genres->'$[0].name' = 'Family'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Fantasy" data="genres->'$[0].name' = 'Fantasy'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Music" data="genres->'$[0].name' = 'Music'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="History" data="genres->'$[0].name' = 'History'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Documentary" data="genres->'$[0].name' = 'Documentary'" link="/time"/>
                        <NormalButtonSmall funcPick={genrePick} text="Animation" data="genres->'$[0].name' = 'Animation'" link="/time"/>
                        
                    </div>
                </div>
                <DontCareButton funcPick={genrePick} text="Actually, I don't care" data={RandomGenre({ option: 4 })} link="/time"/>

            </div>
        );
    };
};

export default GenreAll;