// import genrePick from "../API/Posts"
import Button from 'react-bootstrap/Button';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
// import { Link, useHistory } from 'react-router-dom';
import { FaUndo } from 'react-icons/fa';

export function NormalButton(props) {
  return (
    <Link to={props.link} className="hvr-bob">
      <Button 
        variant="danger" 
        size="customSize" 
        block 
        onClick={(event) => {
          props.funcPick(props.data);
        }}>{props.text}
      </Button>
    </Link>
  )
}

// function linkTo(props) {
//   return (
//     <Link to={props.link} className="hvr-bob">
//     </Link>
//     )
// }

export function StartButton(props) {
  // let history = useHistory()

  return (
    <Link to={props.link} className="hvr-bob-bg">
      <Button 
        variant="outline-warning" 
        size="customSize" 
        block 
        // onClick={(event) => {
        //   props.funcPick();
        // }}
        >{props.text}
      </Button>
    </Link>
  )
}

export function RandomButton(props) {
  // let history = useHistory()

  return (
    <Link to={props.link} className="hvr-bob-bg">
      <Button 
        variant="outline-primary" 
        size="customSize" 
        block 
        // onClick={(event) => {
        //   props.funcPick();
        // }}
        >{props.text}
      </Button>
    </Link>
  )
}
export function NormalButtonSmall(props) {
  return (
    <Link to={props.link} className="hvr-bob-sm">
      <Button 
        variant="danger" 
        size="sm" 
        block 
        onClick={(event) => {
          props.funcPick(props.data)
        }}>{props.text}
      </Button>
    </Link>
  )
}

export function SpecificButton(props) {
  return (
    <Link to={props.link} className="hvr-bob-bg">
      <Button 
        variant="outline-danger" 
        size="customSize" 
        block
        >{props.text}
      </Button>
    </Link>
  )
}

export function DontCareButton(props) {
  
  return (
    <Link to={props.link} className="hvr-bob">
      <Button 
        variant="light" 
        size="customSize" 
        block 
        onClick={(event) => {
          props.funcPick(props.data)
        }}>{props.text}
      </Button>
    </Link>

  )
}

export function DontCareButtonSmall(props) {
  
  return (
    <Link to={props.link} className="hvr-bob">
      <Button 
        variant="light" 
        size="sm" 
        block 
        onClick={(event) => {
          props.funcPick(props.data)
        }}>{props.text}
      </Button>
    </Link>

  )
}
export function RestartButton() {
  
  return (
    <Button
      variant="light" 
      size="customSize" 
      block 
      ><i ><FaUndo className="icon-undo"/> </i>Restart
    </Button>
  )
}

// export function RerollButton(props) {
  
//   return (
//     <Button
//       variant="outline-danger" 
//       size="lg" 
//       block
//       onClick={(event) => {props.func()}}
//       >Re-roll
//     </Button>
//   )
// }

// export function GoogleButton() {
  
//   return (
//     <Button
//       variant="light" 
//       size="lg" 
//       block 
//       >Restart
//     </Button>
//   )
// }
export default NormalButton;